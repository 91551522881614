<template>
  <div class="button-loading">
    <div class="bb-spinner bb-spinner--button">
      <div class="bb-spiner__content">
        <div class="bb-spinner__dots">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingButton",
};
</script>
