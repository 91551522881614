<template>
  <div class="bb-form-group">
    <input
      v-bind="$attrs"
      type="text"
      maxlength="10"
      ref="input"
      readonly="readonly"
      :placeholder="$t('components.calendar.dateFormat')"
      :value="dateToShow"
      @change="onChange"
      class="bb-form"
      :class="inputClass"
      @blur="$emit('blur')"
    />
    <div class="bb-form-group__ico">
      <img
        class="bb-datepiker__ico"
        @click="picker.show()"
        src="../assets/images/calendar.png"
        ref="calendarImage"
        loading="lazy"
        alt="calendar"
      />
    </div>
  </div>
</template>


<script>
import moment from "moment";
import "pikaday/css/pikaday.css";

// TODO: Is this needed?
moment.locale("moment_es");

export default {
  name: "DatePicker",
  model: {
    prop: "date",
    event: "change"
  },
  props: ["date", "inputClass"],
  computed: {
    dateToShow() {
      if (!this.date || this.date == "Invalid date") return "";
      return moment(this.date).format(this.$t("components.calendar.dateFormat"));
    },
    currentDateForPicker() {
      return moment(this.date).format("YYYY-MM-DD");
    },
  },
  methods: {
    onChange(e) {
      const newDate = e.target.value;

      this.$emit(
        "change",
        moment(newDate, this.$t("components.calendar.dateFormat")).format("yyyy-MM-DD")
      );

      const formattedDate = moment(newDate, this.$t("components.calendar.dateFormat")).format("YYYY-MM-DD");

      this.$emit("input", formattedDate);
    },
  },
  mounted() {
    this.picker = null;
    import("pikaday").then((Pikaday) => {
      let picker = new Pikaday.default({
        field: this.$refs.input,
        firstDay: 1,
        format: this.$t("components.calendar.dateFormat"),
        toString(date, format) {
          return moment(date).format(format);
        },
        parse(dateString, format) {
          return moment(dateString, "YYYY-MM-DD").format(format);
        },
        i18n: {
          previousMonth: this.$t("components.calendar.previousMonth"),
          nextMonth: this.$t("components.calendar.nextMonth"),
          months: [
            this.$t("components.calendar.months.01"),
            this.$t("components.calendar.months.02"),
            this.$t("components.calendar.months.03"),
            this.$t("components.calendar.months.04"),
            this.$t("components.calendar.months.05"),
            this.$t("components.calendar.months.06"),
            this.$t("components.calendar.months.07"),
            this.$t("components.calendar.months.08"),
            this.$t("components.calendar.months.09"),
            this.$t("components.calendar.months.10"),
            this.$t("components.calendar.months.11"),
            this.$t("components.calendar.months.12"),
          ],
          weekdays: [
            this.$t("components.calendar.weekdays.sunday"),
            this.$t("components.calendar.weekdays.monday"),
            this.$t("components.calendar.weekdays.tuesday"),
            this.$t("components.calendar.weekdays.wednesday"),
            this.$t("components.calendar.weekdays.thursday"),
            this.$t("components.calendar.weekdays.friday"),
            this.$t("components.calendar.weekdays.saturday"),
          ],
          weekdaysShort: [
            this.$t("components.calendar.weekdays.sundayShort"),
            this.$t("components.calendar.weekdays.mondayShort"),
            this.$t("components.calendar.weekdays.tuesdayShort"),
            this.$t("components.calendar.weekdays.wednesdayShort"),
            this.$t("components.calendar.weekdays.thursdayShort"),
            this.$t("components.calendar.weekdays.fridayShort"),
            this.$t("components.calendar.weekdays.saturdayShort"),
          ]
        }
      });

      this.picker = picker;

      this.$once("hook:destroyed", () => {
        picker.destroy();
        this.picker = null;
      });

      this.$nextTick(() => {
        this.picker.setDate(this.currentDateForPicker);
        this.picker.gotoDate(this.currentDateForPicker);
      });
    });
  }
};
</script>
