<template>
  <alert
    v-if="showAlert"
    @close="closeAlert()"
    :type="alertType"
    :portal="portal"
  >
    <div class="alert__content">
      {{ alertMessage }}
      <span
        class="alert__now"
        v-if="showRetry2 && !alertRetrying"
        @click="retry()"
        >Vuelve a intentarlo</span
      >
      <span class="alert__now" v-if="alertRetrying">Intentándolo de nuevo</span>
    </div>
  </alert>
</template>

<script>
import AlertIntern from "./AlertIntern";
import { mapGetters } from "vuex";

export default {
  name: "Alert",
  components: {
    alert: AlertIntern,
  },
  props: {
    portal: {
      type: String,
      default: "alerts",
    },
  },
  computed: {
    ...mapGetters({
      showAlert: "showAlert",
      alertMessage: "alertMessage",
      alertType: "alertType",
      showRetry: "showRetry",
      alertRetrying: "alertRetrying",
    }),
    showRetry2() {
      return this.alertType === "error" && this.showRetry;
    },
  },
  methods: {
    retry() {
      this.$emit("retry");
    },
    closeAlert() {
      this.$store.commit("setShowAlert", false);
      this.$alert.clearTimeOut();
    },
  },
  watch: {
    showAlert() {
      if (this.showAlert) {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
      }
    },
  },
};
</script>
