<template>
  <div class="g-user-sidebar__left">
    <div class="user-menu">
      <h1 class="user-menu__title">{{ $t("settings.title") }}</h1>
      <ul class="user-menu__nav user-menu__nav--nav">
        <li
          class="user-menu__nav-item"
          :class="{ active: isConfigurationActive }"
          @click="$router.push({ name: 'configuration' })"
        >
          <img
            src="../../../assets/img/ico/ico-nav-user-circle.svg"
            loading="lazy"
            alt=""
          />
          <a>{{ $t("settings.myProfile") }}</a>
        </li>

        <li class="user-menu__nav-item" :class="{ active: isMyListActive }" @click.prevent="$router.push({ name: 'configuration-mylist' })">
          <img
            src="../../../assets/img/ico/ico-nav-list.svg"
            loading="lazy"
            alt=""
          />

          <a>{{ $t("settings.myList")}}</a
          >
        </li>

        <li class="user-menu__nav-item" :class="{ active: isMyPiggyBankActive }" @click.prevent="$router.push({ name: 'configuration-mypiggybank' })" v-if="!hidePiggyBank">
          <img
            src="../../../assets/img/ico/ico-nav-hucha.svg"
            loading="lazy"
            alt=""
          />

          <a>{{ $t("settings.myPiggybank") }}</a>
        </li>

        <!--            TODO ACTIVAR QUAN ESTIGUIN LES NOVES FUNCIONALITATS -->
        <!--            <li class="list-item-3 darrer">-->
        <!--              <img src="../../assets/images/settings.png" loading="lazy" alt="" class="image-53">-->
        <!--              <div class="menu"  :class="{active: isEmailConfigurationActive}">-->
        <!--                <router-link-->
        <!--                    to="/user"-->
        <!--                    aria-current="page"-->
        <!--                    class="link-block-5 w-inline-block w&#45;&#45;current"-->
        <!--                >-->
        <!--                  Configuración emails-->
        <!--                </router-link>-->
        <!--              </div>-->
        <!--            </li>-->
        <!--            <li class="list-item-3 darrer">-->
        <!--              <img src="../../assets/images/settings.png" loading="lazy" alt="" class="image-53">-->
        <!--              <div class="menu" :class="{active: isInviteActive}">-->
        <!--                <router-link-->
        <!--                    to="/user"-->
        <!--                    aria-current="page"-->
        <!--                    class="link-block-5 w-inline-block w&#45;&#45;current"-->
        <!--                >-->
        <!--                  Invitar-->
        <!--                </router-link>-->
        <!--              </div>-->
        <!--            </li>-->
      </ul>
    </div>

    <ul class="user-menu__nav">
      <li @click="logout()" class="user-menu__nav-item user-menu__nav-item--logout">
        <img
          src="../../../assets/img/ico/ico-nav-logout.svg"
          loading="lazy"
          alt=""
        />
        <a>{{ $t("settings.logout") }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CanLogout from "../../../Mixins/CanLogout";

export default {
  name: "LeftMenu",
  mixins: [CanLogout],
  props: {
    active: {
      type: String,
      required: true,
    },
  },
  methods: {
    isActive(menu) {
      return this.active === menu;
    },
  },
  computed: {
    ...mapGetters({
      hidePiggyBank: "hidePiggyBank",
    }),
    isConfigurationActive() {
      return this.isActive("configuration");
    },
    isMyListActive() {
      return this.isActive("my-list");
    },
    isMyPiggyBankActive() {
      return this.isActive("my-piggybank");
    },
    isEmailConfigurationActive() {
      return this.isActive("email-configuration");
    },
    isInviteActive() {
      return this.isActive("invite");
    },
  },
};
</script>
