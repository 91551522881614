import { mapGetters } from "vuex";

export default {
  data() {
    return {
      listAvailable: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "ownerList",
    }),
  },
  methods: {
    async getList(server = false) {
      return this.$store.dispatch("getOwnerList", server);
    },
    async getListFromServer() {
      return await this.getList(true);
    },
    async getListActive() {
      const connectedUser = await this.$store.dispatch("getConnectedUser");
      return connectedUser.listActive;
    },
  },
  async serverPrefetch() {
    return await this.getListFromServer();
  },
  async mounted() {
    // Not using `await` here to make the requests in background and avoid
    // blocking this method.
    const listActivePromise = this.getListActive();

    if (!this.list) {
      await this.getList();
    } else {
      if (!this.list.server) {
        await this.getList();
      }
    }

    // This request was started in background, but we still need to wait for it
    // to finish.
    await listActivePromise;

    this.listAvailable = true;
  },
};
