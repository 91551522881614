<template>
  <alert portal="alerts_configuration" @retry="$emit('retry')" :key="id || $vnode.key">
    <slot></slot>
  </alert>
</template>

<script>
import Alert from "../../../components/ui/Alert";

export default {
  name: 'ConfigurationAlert',
  components: {
    'alert': Alert
  },
  props: {
    id: {
      type: String,
      default: null
    }
  }
}
</script>
